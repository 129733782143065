<template>
    <el-watermark :font="font" :content="uname">
        <el-container :style="'height: '+innerHeight+'px; width: '+ screenWidth +'px;'">
            <el-drawer v-model="drawer" direction="ltr" size='45%'>
                <el-menu mode="vertical" :default-active="uri" :router="true" :ellipsis="false">
                    <el-menu-item :index="'/'+item.path" v-for="item in route" :key="item.id">{{item.authNme}}
                    </el-menu-item>
                </el-menu>
            </el-drawer>
            <el-header v-show="uri != '/Login'? true : false">
                <div style="margin-right:auto;" class="hidden-sm-and-up">
                    <el-button @click="drawer = true" icon="Expand" size="small">菜单</el-button>
                </div>
                <el-menu :popper-offset="15" mode="horizontal" :default-active="uri" :router="true" :ellipsis="false"
                    active-text-color="#fff" background-color="transparent" text-color="#888ba0" class="hidden-xs-only">
                    <el-menu-item :index="'/'+item.path" v-for="item in route" :key="item.id">{{item.authNme}}
                    </el-menu-item>
                </el-menu>
                <div style="margin-left:auto">

                    <el-popover trigger="hover" content="你说的,我都愿意听(*^▽^*)">
                        <template #reference>
                            <el-link style="margin-right:20px" icon="Message" type="info"
                                href="https://www.wenjuan.com/s/UZBZJvGjUt/" target="_blank">心语信箱</el-link>
                        </template>
                    </el-popover>
                    <el-popover trigger="hover" content="生成密钥">
                        <template #reference>
                            <el-button size="small" icon="Key" circle plain @click="Generatekey" />
                        </template>
                    </el-popover>
                    <el-popover trigger="hover" content="批量上传数据">
                        <template #reference>
                            <el-button size="small" icon="UploadFilled" circle plain @click="Upload"
                                :type="uri == '/Upload' ? 'primary': 'info'" v-show="akey=='管理员'" />
                        </template>
                    </el-popover>
                    <el-popover trigger="hover" :content="'退出'+uname+'的账号'">
                        <template #reference>
                            <el-button size="small" icon="SwitchButton" type="primary" circle
                                @click="logouts"></el-button>
                        </template>
                    </el-popover>
                </div>
            </el-header>
            <el-main>
                <router-view />
            </el-main>
        </el-container>
        <el-dialog v-model="centerDialogVisible" :title="'密钥：'+key" width="500" center>
            <template #footer>
                <el-button @click="Generatekey">刷新</el-button>
                <el-button type="primary" @click="centerDialogVisible = false">
                    确认
                </el-button>
            </template>
        </el-dialog>
    </el-watermark>
</template>

<script>
    import axios from "@/utils/request";
    import * as dd from 'dingtalk-jsapi';
    import {
        mapState
    } from 'vuex'
    import store from '@/store'
    export default {
        name: 'app',
        computed: {
            // Vue计算属性
            ...mapState(['uri', 'route', 'uname', 'akey'])
        },
        mounted() {
            //获取屏幕尺寸
            // 高度: document.body.clientHeight
            this.innerHeight = document.documentElement.clientHeight
            store.dispatch('SETIH', document.documentElement.clientHeight)
            window.addEventListener('resize', () => {
                this.innerHeight = document.documentElement.clientHeight
                store.dispatch('SETIH', document.documentElement.clientHeight)
            }, false)
            // 宽度: document.body.clientWidth
            this.screenWidth = document.documentElement.clientWidth
            store.dispatch('SETSW', document.documentElement.clientWidth)
            window.addEventListener('resize', () => {
                this.screenWidth = document.documentElement.clientWidth
                store.dispatch('SETSW', document.documentElement.clientWidth)
            }, false)
        },

        data() { // 可直接挂载到data数据上
            return {
                centerDialogVisible: false,
                key: '',
                innerHeight: '',
                screenWidth: '',
                drawer: false,
                font: {
                    fontSize: 14,
                    color: 'rgba(0, 0, 0, 0.08)'
                }
            }
        },
        methods: {
            logouts() { //退出登录
                axios({
                    method: "get",
                    url: 'logout'
                })
                window.sessionStorage.clear()
                window.localStorage.clear()
                window.location.reload()
                dd.quitPage({
                    success: () => {},
                    fail: () => {},
                    complete: () => {},
                });
            },
            Upload() {
                this.$router.push(`/Upload`)
            },
            Generatekey() {
                if (!this.centerDialogVisible) {
                    this.centerDialogVisible = true
                }
                this.key = '获取中...'
                axios({
                    method: "post",
                    url: 'getdata',
                    data: {
                        tabname: '获取一个密钥',
                        search: {},
                        csearch: null,
                    },
                }).then(res => {
                    this.key = res.key
                })
            }
        }
    }
</script>

<style scoped>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    nav {
        padding: 30px;
    }

    nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    nav a.router-link-exact-active {
        color: #42b983;
    }


    .el-header,
    .el-footer {
        background-color: #171e49;
        color: #333;
        text-align: center;
        line-height: 60px;
    }

    .el-aside {
        position: relative;
        background-color: rgb(0, 21, 41);
        color: #fff;
        text-align: center;
        line-height: 200px;
    }

    .el-menu {
        border-right: 0;
        text-align: left;
    }

    .el-header {
        display: flex;
        justify-content: left;
        text-align: center;
    }

    .header_img {
        padding: 5px;
        width: 178px;
    }
</style>