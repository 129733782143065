<template>
    <CatTabs @tabClick='handleClick' :tabsName='tabsName' :tabname='lookup.tabname'>
        <template #search-r>
            <CatSearch :searchHead="tableHead" @search="getdatas(true)" :searchForm='lookup.search' />
        </template>
        <template #search>
            <el-date-picker v-model="lookup.search.trade_date" type="daterange" range-separator="-"
                start-placeholder="开始时间" format="YYYY-MM-DD" value-format="YYYY-MM-DD" end-placeholder="结束时间"
                :disabled-date="disabledDate" @calendar-change='pickerOptions' :visible-change="timeOptionRange=''"
                v-if="searchH === 'date-picker'" @change="getdatas()" size="small" />
            <el-date-picker v-model="lookup.search.trade_date[0]" type="date" placeholder="选择时间" format="YYYY-MM-DD"
                value-format="YYYY-MM-DD" range-separator="-" v-else-if="searchH === 'one-date-picker'"
                @change="getdatas()" size="small" />
            <el-input v-model="lookup.csearch" placeholder="聚合搜索" clearable prefix-icon="Search" v-else size="small"
                @change="getdatas()" />
        </template>
        <template #search-l>
            <el-button-group>
                <el-button size="small" type="primary" @click="addv()" v-if="counterpoise_add">添加
                </el-button>
                <CatUpload :tableHead='tableHead' :tabsName='this.lookup.tabname' :modifyData='counterpoise_edit'
                    v-if="counterpoise_import"></CatUpload>
                <el-button size="small" type="primary" @click="ExporttAPI('#table-data')">导出表
                </el-button>
            </el-button-group>
        </template>
        <template #content>
            <CatTable :fileList='fileList' :tableHead='tableHead' :lookup='lookup' @currentPage='handleCurrentChange'
                @rowvl='tabClick' :loading='loading' />
        </template>
    </CatTabs>
    <CatChange :changedits='edit' @updatas='updatas' @deletes='deletes' @cancels="edit = false" :changeForms='form'
        :changeHeads='tableHead' />
    <CatAdd :changedits='addedit' @updatas='updatas' @deletes='deletes' @cancels="addedit = false" :changeForms='form'
        :changeHeads='tableHead' />
</template>

<script>
    import CatTabs from '@/components/CatTabs.vue'
    import CatAdd from '@/components/CatAdd.vue'
    import CatChange from '@/components/CatChange.vue'
    //导出文件
    import jsonToExcel from '@/utils/jsonToExcel'
    import CatSearch from '@/components/CatSearch.vue'
    import CatTable from '@/components/CatTable.vue'
    import CatUpload from '@/components/CatUpload.vue'
    import axios from "@/utils/request";
    import {
        mapState
    } from 'vuex'
    import {
        getabs,
        geTaday
    } from '@/utils/tools'
    import {
        ElMessage,
        ElLoading,
    } from 'element-plus'
    export default {
        name: 'MyData',
        components: {
            CatChange,
            CatSearch,
            CatTabs,
            CatAdd,
            CatTable,
            CatUpload
        },
        computed: {
            // Vue计算属性
            ...mapState(['today'])
        },
        created() {
            //获取侧边列表
            getabs().then(v => {
                this.tabsName = v
                this.lookup.tabname = v[0]
                this.lookup.search['trade_date'] = [this.today, this.today]
                this.getdatas(true)
            })
        },
        data() {
            return {
                olddata: {},
                searchH: null,
                newSearchHead: [],
                tabsName: [],
                columns: [],
                form: {},
                lookup: {
                    tabname: '',
                    modifyData: false, //修改指令
                    export: false,
                    search: {},
                    csearch: null,
                    currentPage: 1, //当前所在分页页数
                    pageSize: 20, //一页显示多少数据
                    total: 20, //总共有多少数据
                    data: {},
                    modifysrt: ''
                },
                counterpoise_add: false,
                counterpoise_search: true,
                counterpoise_edit: false,
                counterpoise_import: false,
                tableHead: [],
                timeOptionRange: '',
                edit: false,
                loading: false,
                fileList: [],
                addedit: false
            }
        },
        methods: {
            tableRowClassName({ // 把每一行的索引放进row 删除引用
                row,
                rowIndex
            }) {
                row.index = rowIndex
            },
            handleCurrentChange(v) {
                this.lookup.currentPage = v
                this.getdatas()
            },
            updatas() {
                this.edit = false
                // 统计数据变化开始
                let modifysrt = []
                this.tableHead.forEach((items) => {
                    if (items.edit && this.olddata[items.property] != this.form[items.property]) {
                        modifysrt.push(
                            `${items.label}:${this.olddata[items.property]}=>${this.form[items.property]}`
                        )
                    }
                })
                if (modifysrt.length == 0) {
                    ElMessage({
                        type: "warning",
                        message: `数据无变化！`,
                    })
                    return
                }
                this.lookup.modifysrt = modifysrt.join(',')
                // 统计数据变化结束
                this.lookup.data[0] = this.form
                this.loading = true
                this.addedit = false
                axios({
                    method: "post",
                    url: 'updata',
                    data: this.lookup,
                }).then(res => {
                    this.loading = false
                    if (res.add == 0 && res.update == 0) {
                        return
                    }
                    ElMessage({
                        type: "success",
                        message: `添加${res.add}行数据，更新${res.update}行数据`,
                    })
                    if (res.addate) {
                        res.addate.map((vs) => {
                            this.fileList.push(vs)
                        })
                    }

                })
            },
            deletes(v) {
                this.form = v
                this.edit = false
                this.addedit = false
                if (this.form.index != undefined) {
                    this.fileList.splice(this.form.index, 1)
                }
            },
            pickerOptions(time) {
                //保存用户在设置日期选择器选择的第一个日期
                this.timeOptionRange = time[0];
            },
            disabledDate(time) {
                let dataOptionRange = this.timeOptionRange;
                let secondNum = 60 * 60 * 24 * 30 * 1000; // 时间跨度为7天【一年以365天的时间计算】的限制
                if (dataOptionRange) {
                    return time.getTime() > dataOptionRange.getTime() + secondNum || time.getTime() < dataOptionRange
                        .getTime();
                }
            },
            addv() { //增加
                this.form = {}
                this.addedit = true
            },
            handleClick(v) {
                // 切换表
                this.lookup.currentPage = 1
                this.fileList.length = 0
                this.lookup.tabname = v
                this.lookup.search = {
                    trade_date: this.lookup.search.trade_date
                }
                this.lookup.csearch = null
                this.getdatas()
            },
            tabClick(row, column, cell, event) {
                //筛选数据
                this.edit = false
                if (this.counterpoise_edit) {
                    this.edit = true
                }
                this.form = row
                this.olddata = JSON.parse(JSON.stringify(row))
            },
            //获取数据
            getdatas(aggregationInterceptor = false) {
                // 聚合拦截器
                if (aggregationInterceptor) {
                    this.lookup.csearch = null
                }
                this.loading = true
                axios({
                    method: "post",
                    url: 'getdata',
                    data: this.lookup
                }).then(res => {
                    this.fileList = res.data //数据本身
                    this.tableHead = res.tableHead.tableHead //数据表头
                    this.searchH = this.tableHead[0].type
                    this.newSearchHead = this.tableHead.map(item => item.property);
                    this.lookup.total = res.total
                    this.counterpoise_add = res.tableHead.add //添加权限
                    this.counterpoise_search = res.tableHead.search //点击编辑
                    this.counterpoise_edit = res.tableHead.edit == 1 ? true : false //点击编辑
                    this.lookup.modifyData = res.tableHead.edit == 1 ? true : false //点击编辑
                    this.counterpoise_import = res.tableHead.import == 1 ? true : false //点击编辑
                    this.loading = false
                })
            },
            ExporttAPI(e) { //导出表
                this.loading = true
                this.lookup.export = true
                axios({
                    method: "post",
                    url: 'getdata',
                    data: this.lookup,
                }).then(res => {
                    jsonToExcel(res.data, res.tableHead.tableHead, this.lookup.tabname)
                    this.loading = false
                })
                this.lookup.export = false
            }
        }
    }
</script>

<style scoped>
    .el-form-item {
        width: 45%;
    }


    .el-menu-vertical-demo {
        height: 100%;
    }

    .el-menu {
        min-height: 415px;
    }

    /* .card-header {
        padding-left: 16px;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    } */
</style>